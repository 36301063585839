/* You can add global styles to this file, and also import other style files */
@import 'variable';

.f-w-300 {
  font-weight: 300;
}

.f-30 {
  font-size: 30px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.f-24 {
  font-size: 24px;
}

.f-34 {
  font-size: 34px;
}

.f-w-bold {
  font-weight: bold;
}

.f-c-title {
  color: #2a2a2a;
}

.f-c-sub-title {
  color: black;
}

.f-w-400 {
  font-weight: 400;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

* {
  font-family: $font-stack;
  scroll-behavior: smooth;
}

.main-font {
  font-family: $font-stack;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5562eb;

  &:hover {
    color: white;
    background-color: #7681ef;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
  &:hover {
    background-color: #46485a;
  }
}

.ant-menu-item-selected {
  color: white;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #313131;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1b56d3;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f0f0f0;

  .ant-menu-title-content {
    color: #2a2a2a;
  }
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.a-delay-1000 {
  animation-delay: 1000ms;
}

/* For Chrome, Safari, and other WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #f1f1f1; /* Adds padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color on hover */
}

/* For Firefox */
.scrollable-element {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.d-jus-between {
  justify-content: space-between;
}

.d-jus-end {
  justify-content: flex-end;
}

.d-jus-center {
  justify-content: center;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #252525;
  border-color: #252525;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: white;
  color: black;
}

.ant-spin-dot-item {
  background-color: #0043ff;
}

.ant-spin {
  color: #92adfd;
}

.sarabun-thin {
  font-family: 'Sarabun', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.sarabun-extralight {
  font-family: 'Sarabun', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.sarabun-light {
  font-family: 'Sarabun', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.sarabun-regular {
  font-family: 'Sarabun', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.sarabun-medium {
  font-family: 'Sarabun', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.sarabun-semibold {
  font-family: 'Sarabun', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.sarabun-bold {
  font-family: 'Sarabun', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.sarabun-extrabold {
  font-family: 'Sarabun', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.sarabun-thin-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.sarabun-extralight-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.sarabun-light-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.sarabun-regular-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.sarabun-medium-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.sarabun-semibold-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.sarabun-bold-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.sarabun-extrabold-italic {
  font-family: 'Sarabun', sans-serif;
  font-weight: 800;
  font-style: italic;
}

.h-100 {
  height: 100%;
}

.ant-menu-title-content {
  color: white;
}

.mb-24 {
  margin-bottom: 24px;
}

.btn-system {
  transition: opacity 0.5s ease;
  background: linear-gradient(45deg, #e94c79, #ae14c2);
  color: white;
  border: none;

  &:focus {
    background: linear-gradient(45deg, #e94c79, #ae14c2);
    color: white;
    border: none;
  }

  &:hover {
    background: linear-gradient(45deg, #e94c79, #ae14c2);
    color: white;
    opacity: 0.8;
    border: none;
  }
}

.btn-system--danger {
  transition: opacity 0.5s ease;
  background: linear-gradient(45deg, #e22310, #fe614b);
  color: white;
  border: none;

  &:focus {
    background: linear-gradient(45deg, #e22310, #fe614b);
    color: white;
    border: none;
  }

  &:hover {
    background: linear-gradient(45deg, #e22310, #fe614b);
    color: white;
    opacity: 0.8;
    border: none;
  }
}

.btn-system--common {
  transition: opacity 0.5s ease;
  background: linear-gradient(45deg, #10c7e2, #024587);
  color: white;
  border: none;

  &:focus {
    background: linear-gradient(45deg, #10c7e2, #024587);
    color: white;
    border: none;
  }

  &:hover {
    background: linear-gradient(45deg, #10c7e2, #024587);
    color: white;
    opacity: 0.8;
    border: none;
  }
}

.btn-system--normal {
  transition: opacity 0.5s ease;
  background: linear-gradient(45deg, #232323, #7c7c7c);
  color: white;
  border: none;

  &:focus {
    background: linear-gradient(45deg, #232323, #7c7c7c);
    color: white;
    border: none;
  }

  &:hover {
    background: linear-gradient(45deg, #232323, #7c7c7c);
    color: white;
    opacity: 0.8;
    border: none;
  }
}

.ant-table-thead > tr > th {
  color: rgb(255 255 255 / 85%);
  background: #3d3d3d;
}

.app-text-header {
  font-size: 26px;
  font-weight: bold;

  padding: 0 16px 16px 0;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgb(0 0 0 / 68%);
}

.ant-table-thead th.ant-table-column-sort {
  background: #5e5e5e;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #f041b2;
}

//.ant-table-expanded-row td {
//  background: #3d3d3d;
//
//}

.text-white {
  color: white;
}
